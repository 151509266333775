import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Ласкаво просимо до Joy прокат автомобілів
			</title>
			<meta name={"description"} content={"Відкрийте для себе радість простої та комфортної подорожі."} />
			<meta property={"og:title"} content={"Головна | Ласкаво просимо до Joy прокат автомобілів"} />
			<meta property={"og:description"} content={"Відкрийте для себе радість простої та комфортної подорожі."} />
			<meta property={"og:image"} content={"https://brewajoy.com/img/0.jpg"} />
			<link rel={"shortcut icon"} href={"https://brewajoy.com/img/46236236.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://brewajoy.com/img/46236236.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://brewajoy.com/img/46236236.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://brewajoy.com/img/46236236.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://brewajoy.com/img/46236236.png"} />
			<meta name={"msapplication-TileImage"} content={"https://brewajoy.com/img/46236236.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="0px 0 0px 0"
			md-padding="80px 0 50px 0"
			overflow-x="hidden"
			overflow-y="hidden"
			quarkly-title="Hero-14"
		>
			<Override slot="SectionContent" width="100%" min-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				md-flex-direction="column"
				md-justify-content="flex-end"
				md-align-items="flex-end"
				sm-z-index="2"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					width="40%"
					padding="80px 80px 80px 80px"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					sm-padding="50px 25px 50px 25px"
					sm-position="relative"
					sm-z-index="2"
				>
					<Text margin="0px 0px 20px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond" color="--primary">
						Прокат автомобілів
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 600 72px/1.2 --fontFamily-serifGaramond" sm-font="normal 600 42px/1.2 --fontFamily-serifGaramond">
						Joy{"  "}
					</Text>
					<Text margin="0px 0px 60px 0px" font="--base">
					Відкрийте для себе радість простої та комфортної подорожі. У Joy ми віримо, що надаємо вам більше, ніж просто автомобіль - ми пропонуємо плавний, безпроблемний досвід, який робить кожну подорож особливою. Незалежно від того, чи ви у діловій поїздці, чи у відпустці, наш автопарк доглянутих автомобілів до ваших послуг.
					</Text>
					<Button
						background="--color-primary"
						border-radius="4px"
						padding="16px 28px 16px 28px"
						font="--lead"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="solid"
						border-color="--primary"
						hover-color="--darkL1"
						hover-background="rgba(237, 97, 26, 0.03)"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Контакти
					</Button>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					width="60%"
					max-height="800px"
					justify-content="flex-end"
					align-items="center"
					md-margin="-120px 0px 0px 0px"
					md-width="60%"
					sm-position="relative"
					sm-z-index="1"
				>
					<Image
						src="https://brewajoy.com/img/0.jpg"
						display="block"
						margin="0px 0px 0px 0px"
						height="100%"
						md-margin="0px 0 0px 0px"
						width="100%"
						object-fit="cover"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" margin="20px 0 0 0">
			Відчуйте легкість з орендою затишних крейсерських автомобілів
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			Вирушайте у подорож з надійним компаньйоном поруч. Наш асортимент автомобілів, від компактних міських моделей до просторих сімейних авто, обладнаний таким чином, щоб задовольнити будь-які ваші потреби. Ми пишаємося тим, що пропонуємо чистоту, комфорт і зручність у кожній поїздці.
			</Text>
		</Section>
		<Section background="#f9f2f0" padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Image
					src="https://brewajoy.com/img/2.jpg"
					display="block"
					width="40%"
					object-fit="cover"
					md-width="100%"
					object-position="0%"
					max-height="900p"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="50px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 900 42px/1.2 --fontFamily-mono">
					Чому варто обрати Joy?
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						margin="50px 0px 0px 0px"
						grid-gap="16px 24px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box min-width="100px" min-height="100px" padding="25px 25px 25px 25px" background="#ffffff">
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								color="--light"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									01
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
							Персоналізоване обслуговування: Кожен клієнт унікальний, як і наш сервіс. Наша команда прагне зрозуміти ваші потреби та порекомендувати ідеальний автомобіль для вашої подорожі.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								color="--light"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									02
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
							Прозоре ціноутворення: Ніяких прихованих платежів, ніяких сюрпризів. Ми віримо в чітке, заздалегідь визначене ціноутворення, що гарантує надійний досвід оренди.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								color="--light"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									03
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
							Допомога на дорозі: Душевний спокій гарантовано завдяки нашій цілодобовій допомозі на дорозі, яка готова підтримати вас у будь-якій непередбачуваній ситуації.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								border-radius="50%"
								color="--light"
								background="--color-primary"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet" transform="rotateZ(-15deg)">
									04
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
							Екологічні варіанти: Для тих, хто піклується про навколишнє середовище, ми пропонуємо вибір паливно-ефективних та гібридних транспортних засобів, що сприяють більш екологічній подорожі.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});